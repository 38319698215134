import { Brand, getClientIdForELN } from '@scm/ui-core'

export interface ElnUserRegistrationOptions {
  iirisProfileId: string
  accessToken: string
}

export class Eln {
  baseUrl: string
  brand: Brand
  constructor(baseUrl: string, brand: Brand) {
    this.baseUrl = baseUrl
    this.brand = brand
  }

  async putUserRegistration({
    iirisProfileId,
    accessToken,
  }: ElnUserRegistrationOptions) {
    return fetch(`${this.baseUrl}/v1/user-registrations/${iirisProfileId}`, {
      method: 'PUT',
      headers: {
        Authorization: accessToken,
        'x-site-id': getClientIdForELN(this.brand),
      },
    })
  }

  async patchUserModification({
    iirisProfileId,
    accessToken,
  }: ElnUserRegistrationOptions) {
    return fetch(`${this.baseUrl}/v1/user-registrations/${iirisProfileId}`, {
      method: 'PATCH',
      headers: {
        Authorization: accessToken,
        'x-site-id': getClientIdForELN(this.brand),
      },
    })
  }
}
