import { Environment } from '@scm/ui-types'
import { ScriptDic } from '../types'

export const authenticationLinks = [
  {
    rel: 'stylesheet',
    href: 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
  },
  {
    rel: 'stylesheet',
    href: 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css',
  },
]

export const PASSPORT_SCRIPT_ID = 'passport-loader'
export const IDENTITY_SCRIPT_ID = 'identity-script'
export const PROFILEMGR_SCRIPT_ID = 'profilemanager-script'
export const REGISTER_BTN_ID = 'irisRegisterBtn'
export const LOGIN_BTN_ID = 'irisLoginBtn'
export const LOGOUT_BTN_ID = 'irisLogoutBtn'
export const RELOAD_BTN_ID = 'irisReloadBtn'

export const getIdentityScriptUrl = (environment?: Environment) => {
  return environment === 'prod'
    ? 'https://widgets.iiris.com/iiris-passport/v4.0/iiris-identity.js'
    : 'https://widgets-uat.iiris.com/iiris-passport/v4.0/iiris-identity.js'
}

export const getPassportScriptUrl = (environment?: Environment) => {
  return environment === 'prod'
    ? 'https://widgets.iiris.com/iiris-passport/passport-loader.js'
    : 'https://widgets-uat.iiris.com/iiris-passport/passport-loader.js'
}

export const getProfileMgrScriptUrl = (environment?: Environment) => {
  return environment === 'prod'
    ? 'https://widgets.iiris.com/iiris-passport/v4.0/iiris-profilemgr.js'
    : 'https://widgets-uat.iiris.com/iiris-passport/v4.0/iiris-profilemgr.js'
}

export function isProfileMgrLoaded(): boolean {
  // @ts-expect-error
  const pm = typeof window !== 'undefined' ? window.IIRISProfileMgr : {}
  return Object.hasOwn(pm, 'getIIRISProfile')
}

export function isIdentityLoaded(): boolean {
  const registerBtn = document.getElementById(REGISTER_BTN_ID)?.id
  const reloadBtn = document.getElementById(RELOAD_BTN_ID)?.id
  return Boolean(registerBtn || reloadBtn)
}

export const scriptDic: ScriptDic = {
  Identity: {
    name: 'isIdentityScriptLoaded',
    id: IDENTITY_SCRIPT_ID,
    get: getIdentityScriptUrl,
    check: isIdentityLoaded,
  },
  ProfileMgr: {
    name: 'isProfileMgrScriptLoaded',
    id: PROFILEMGR_SCRIPT_ID,
    get: getProfileMgrScriptUrl,
    check: isIdentityLoaded,
  },
  Passport: {
    name: 'isPassportScriptLoaded',
    id: PASSPORT_SCRIPT_ID,
    get: getPassportScriptUrl,
    check: () => true,
  },
}
