import { useEffect } from 'react'
import { getGuestSession } from './guest'
import { selectors, useAppDispatch, actions } from '@scm/redux-store'
let useClientSDKTimeout: NodeJS.Timeout
export const useClientSDK = () => {
  const { isLoaded, isClientLoaded, isPassportScriptLoaded } =
    selectors.useAuth()
  const dispatch = useAppDispatch()
  const loadSession = async () => {
    const guestSession = await getGuestSession()
    if (guestSession) {
      dispatch(actions.auth.patch({ isClientLoaded: true }))
    } else if (isPassportScriptLoaded) {
      useClientSDKTimeout = setTimeout(loadSession, 1_000)
    }
  }
  useEffect(() => {
    if (isLoaded && !isClientLoaded) {
      useClientSDKTimeout = setTimeout(loadSession, 2_000)
    }
    return () => {
      clearTimeout(useClientSDKTimeout)
    }
  }, [isLoaded])
}
