import { useRef } from 'react'
import { handle } from './utils/reduxHandle'
import {
  IDENTITY_SCRIPT_ID,
  PROFILEMGR_SCRIPT_ID,
  scriptDic,
} from './utils/constants'
import { Environment } from '@scm/ui-types'
import { useClientEffect } from '@scm/ui-core'
import { ScriptTypes } from './types'

type ScriptListProps = {
  env: Environment
}

export const ScriptList = ({ env }: ScriptListProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const logger = (...args: any[]) => {
    // eslint-disable-next-line no-console
    if (env !== 'prod') console.log(`%c[iris]: `, 'color:orangered', ...args)
  }

  function appendScripts(checkFirst = false) {
    const fragment = document.createDocumentFragment()
    const list: ScriptTypes[] =
      env === 'local' ? ['Passport'] : ['Identity', 'ProfileMgr']

    for (const key of list) {
      const { get, check, id, name } = scriptDic[key]
      // check: if the script is fully loaded, skip it.
      if (checkFirst && check()) continue
      // remove the old element first
      document.getElementById(id)?.remove()
      // prepare the new element
      const element = document.createElement('script')
      element.onload = () => {
        handle.scriptLoaded(name)
      }

      element.id = id
      element.src = get(env)
      element.setAttribute('data-testid', id)
      fragment.appendChild(element)
    }
    ref.current?.appendChild(fragment)
    logger('appended scripts')
  }

  useClientEffect(() => {
    let attempts = 0
    const iirisScriptCheckerTimer = setInterval(() => {
      logger(`Interval check: #${attempts}`)
      const identityScr = document.getElementById(IDENTITY_SCRIPT_ID)
      const profileMgrScr = document.getElementById(PROFILEMGR_SCRIPT_ID)

      if (identityScr?.id && profileMgrScr?.id) {
        const identityReady = scriptDic.Identity.check()
        const profileMgrReady = scriptDic.ProfileMgr.check()
        logger(`identity script ${identityReady ? 'ready' : 'pending'}`)
        logger(`profileMgr script ${profileMgrReady ? 'ready' : 'pending'}`)
        if (identityReady && profileMgrReady) {
          logger('scripts ready')
          handle.scriptLoaded('isPassportScriptLoaded')
          clearInterval(iirisScriptCheckerTimer)
        } else {
          if (++attempts > 6) {
            attempts = 0
            // an extra manuver to re-attach the scripts when they are not loaded correctly
            logger('restarting scripts')
            appendScripts(true)
          }
        }
      }
    }, 500)
    appendScripts()
    return () => {
      clearInterval(iirisScriptCheckerTimer)
    }
  }, [])
  return <div ref={ref} id="script-list" suppressHydrationWarning={true} />
}
