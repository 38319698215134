import { IIirisGuestSession } from '../types'

export const getGuestSession = async (): Promise<IIirisGuestSession | null> => {
  try {
    // @ts-expect-error
    const IIRISProfileMgr = await window.IIRISProfileMgr
    if (IIRISProfileMgr) {
      const guestSession: IIirisGuestSession =
        await IIRISProfileMgr.getGuestSession()

      return Promise.resolve(guestSession)
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error(err)
  }
  return null
}
