export const assignToWindow = (name: string, callback: any) => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    // @ts-expect-error
    window[name] = callback
  }
}
export const assignModuleToWindow = (module: Record<string, any>) => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    Object.assign(window, module)
  }
}

export const assignModuleToWindowStrict = (module: Record<string, any>) => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    Object.keys(module).forEach(m => {
      // @ts-expect-error
      if (typeof window[m] === 'undefined') window[m] = module[m]
    })
  }
}
