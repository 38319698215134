import { ReduxState, ScriptLoaded } from '../types'
import { userPicker } from './userPicker'
import { EventType, GTM } from '@scm/tag-manager'
import { store, AuthState, actions, storeAuthData } from '@scm/redux-store'

type UserUpdated = {
  user: AuthState['user']
  token?: string | null
  authFlow?: AuthState['authFlow']
  withNewsletterPromo?: boolean
}

class reduxHandle {
  brand?: string
  env?: string
  init = (brand: string, env: string) => {
    this.brand = brand
    this.env = env
  }
  scriptLoaded = (payload: ScriptLoaded) => {
    store.dispatch(actions.auth.scriptLoaded({ [payload]: true }))
  }
  userUpdated = (payload: UserUpdated, event?: boolean) => {
    const dataLayerUser = userPicker(payload.user)
    if (event) {
      GTM.utils.pushToDataLayer({
        user: dataLayerUser,
        _clear: true,
        event: EventType.LOGIN,
      })
    }
    store.dispatch(actions.auth.updateUser({ ...payload, dataLayerUser }))
    if (this.brand && this.env) {
      storeAuthData(this.brand, this.env, {
        dataLayerUser,
        sessionType: 'user',
        user: payload.user,
        token: payload.token,
        authFlow: payload.authFlow,
        accessToken: payload.token ? `Bearer ${payload.token}` : '',
      })
    }
  }
  userPatched = (payload: AuthState['user']) => {
    const { token, accessToken } = store.getState().auth
    const dataLayerUser = userPicker(payload)

    store.dispatch(
      actions.auth.updateUser({ user: payload, dataLayerUser, token }),
    )
    if (this.brand && this.env) {
      storeAuthData(this.brand, this.env, {
        dataLayerUser,
        sessionType: 'user',
        user: payload,
        token: token,
        authFlow: 'login',
        accessToken: accessToken,
      })
    }
  }
  userClear = (event?: boolean) => {
    const { dataLayerUser, user } = store.getState().auth
    if (user === null) return
    if (event) {
      GTM.utils.pushToDataLayer({
        user: dataLayerUser,
        _clear: true,
        event: EventType.LOGOUT,
      })
    }
    store.dispatch(actions.auth.clearUser())
    if (this.brand && this.env) {
      storeAuthData(this.brand, this.env, {})
    }
  }
  updateAuthFlow = (authFlow: ReduxState['authFlow']) => {
    store.dispatch(actions.auth.patch({ authFlow }))
  }
  authenticationStarted = () => {
    store.dispatch(actions.auth.patch({ authenticationPending: true }))
  }
  authenticationFinished = () => {
    store.dispatch(actions.auth.patch({ authenticationPending: false }))
  }
  patch = (payload: Partial<AuthState>) => {
    store.dispatch(actions.auth.patch(payload))
  }
}
export const handle = new reduxHandle()
