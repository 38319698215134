import SHA256 from 'crypto-js/sha256'
import Cookies from 'js-cookie'
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { EventType, GTM, RegistrationStatus } from '@scm/tag-manager'
import { useNavigate } from '@remix-run/react'

import { useClientSDK } from './utils/client'
import { Eln } from './eln'
import { handle } from './utils/reduxHandle'
import {
  AuthenticationState,
  IAuthenticationProvider,
  IIrisCallback,
  RegFn,
} from './types'
import {
  createAccessToken,
  validateProfile,
  getIIRISProfileMgr,
  assignModuleToWindow,
} from './utils'
import { selectors } from '@scm/redux-store'
import { ScriptList } from './scriptList'
import { Root, useClientEffect } from '@scm/ui-core'
import { LOGIN_BTN_ID, LOGOUT_BTN_ID, REGISTER_BTN_ID } from './utils/constants'

const sendRegistrationEvent = (data: any) => {
  GTM.utils.pushToDataLayer(
    { data, _clear: true, event: EventType.REGISTRATION },
    true,
  )
}
export const AuthenticationContext = createContext<AuthenticationState | null>(
  {} as AuthenticationState,
)

export const AuthenticationProvider = (props: IAuthenticationProvider) => {
  return (
    <Root theme={props.theme}>
      {props.enabled && props.authenticationConfigId ? (
        <ProviderComponent {...props} />
      ) : (
        <AuthenticationContext.Provider
          value={{
            login: () => true,
            logout: () => true,
            register: () => true,
            initRegistrationCallbacks: () => true,
            updateUserProfile: async () => undefined,
          }}
        >
          {props.children}
        </AuthenticationContext.Provider>
      )}
    </Root>
  )
}

export const ProviderComponent: FC<IAuthenticationProvider> = ({
  children,
  withEln,
  standaloneRegistrationPage = false,
}) => {
  const {
    brand,
    isLoaded,
    environment,
    isClientLoaded,
    platformServiceBaseUrl,
  } = selectors.useAuth()
  const selectedReferrerRef = useRef<string | undefined>()
  const navigate = useNavigate()
  handle.init(brand, environment)

  const navigateBackToContent = useCallback(
    ({ withPayment = false, withPromptForConsent = false }) => {
      const search = []
      if (withPayment) search.push('redirectToPayment=true')
      if (withPromptForConsent) search.push('withPromptForConsent=true')

      navigate(
        {
          pathname: sessionStorage.getItem('previousURL') || '/',
          search: search.length ? '?' + search.join('&') : undefined,
        },
        { preventScrollReset: true, state: { withSpinner: true } },
      )
    },
    [],
  )

  useClientSDK()

  const eln = useMemo(
    () => (withEln ? new Eln(platformServiceBaseUrl, brand) : undefined),
    [withEln, platformServiceBaseUrl, brand],
  )

  const handleRegistrationSuccess = async ({
    token,
    user,
    withNewsletterPromo,
  }: IIrisCallback & { withNewsletterPromo: boolean }) => {
    if (!user) {
      return
    }

    handle.userUpdated(
      { withNewsletterPromo, user, token, authFlow: 'register' },
      true,
    )

    await eln?.putUserRegistration({
      iirisProfileId: user.id,
      accessToken: createAccessToken(token),
    })

    handle.updateAuthFlow('login')
  }

  const handleLoginSuccess = ({ token, user }: IIrisCallback) => {
    handle.userUpdated({ user, token, authFlow: 'login' }, true)
  }

  const registerStandalone: RegFn = (options = {}) => {
    if (isLoaded) {
      const {
        referrer = '',
        withNewsletterPromo = true,
        withPayment = false,
        withPromptForConsent,
      } = options
      selectedReferrerRef.current = options.referrer
      sendRegistrationEvent({
        name: options.referrer,
        status: RegistrationStatus.ATTEMPT,
      })
      navigate('/register', {
        state: {
          referrer,
          withPayment,
          withNewsletterPromo,
          withPromptForConsent,
        },
      })
    }
  }

  const registerModal: RegFn = (options = {}) => {
    if (isLoaded) {
      const {
        onClose,
        onRegistrationSuccess,
        onLoginSuccess,
        referrer,
        withNewsletterPromo = true,
      } = options

      handle.authenticationStarted()
      assignModuleToWindow({
        irisRegisterCallback: async (props: IIrisCallback) => {
          handle.authenticationFinished()

          if (props.success && props.user) {
            handleRegistrationSuccess({
              ...props,
              withNewsletterPromo,
            })
            onRegistrationSuccess?.(props.user)
          }
        },

        irisLoginCallback: (props: IIrisCallback) => {
          handle.authenticationFinished()

          if (props.success && props.user) {
            handleLoginSuccess(props)
            onLoginSuccess?.(props.user)
          }
        },
      })

      document.getElementById(REGISTER_BTN_ID)?.click()

      selectedReferrerRef.current = referrer

      // when user clicks the initial register button on SCM site
      sendRegistrationEvent({
        name: referrer,
        status: RegistrationStatus.ATTEMPT,
      })

      document
        .querySelector('.iiris-modal-box .iiris-close-icon')
        ?.addEventListener('click', e => {
          if (e.isTrusted) {
            handle.authenticationFinished()
            onClose?.()
          }
        })
    }
  }

  useClientEffect(() => {
    assignModuleToWindow({
      irisProfileMgrCallback: ({ success, token, user }: IIrisCallback) => {
        if (success && user && token) {
          handle.userUpdated({ user, token, authFlow: 'login' })
        }
      },
      scmBusinessEvtCallback: (event: any, asset: any) => {
        const metadata = asset.metadata ? JSON.parse(asset.metadata) : {}
        const { business_email, email, id: userId = '' } = metadata
        const { formId } = event

        const isFormDropout =
          formId === 'registration' && event.status.includes('dropout')
        const userEmail = business_email || email
        const hashedEmail = userEmail && SHA256(userEmail).toString()

        const createRegistrationPayload = (status: RegistrationStatus) => ({
          name: selectedReferrerRef.current || '',
          user_id: userId,
          email_hashed: hashedEmail,
          email: userEmail,
          capture_tool: 'IIRIS',
          status,
        })

        // currently IIRIS has a bug where they send a dropout event after user
        // successfully submits the 'welcome/tell us about yourself' step.
        // we don't want to sent a registration success event when this happens.
        if (formId === 'registration' && !isFormDropout) {
          const data = createRegistrationPayload(RegistrationStatus.SUCCESS)
          sendRegistrationEvent(data)
        }

        if (formId === 'progressive') {
          const data = createRegistrationPayload(
            RegistrationStatus.REG_ADDITIONAL_INFO,
          )
          sendRegistrationEvent(data)
        }
      },
      irisLogoutCallback: ({ success }: IIrisCallback) => {
        if (success) {
          Cookies.remove('ECOM_USER_SESSION', { path: '/' })
          handle.userClear(true)
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const context = useMemo<AuthenticationState>(
    () => ({
      updateUserProfile: async (prequisits, data) => {
        validateProfile(data)

        const result = (
          await getIIRISProfileMgr()?.setIIRISProfile(data)
        )?.data?.pop()
        if (eln) {
          await eln.patchUserModification(prequisits)
        }

        if (result) handle.userPatched(result)
      },
      initRegistrationCallbacks: ({
        referrer,
        withNewsletterPromo,
        withPayment,
        withPromptForConsent,
      }) => {
        selectedReferrerRef.current = referrer
        assignModuleToWindow({
          irisRegisterCallback: async (props: IIrisCallback) => {
            if (props.success && props.user) {
              handleRegistrationSuccess({
                ...props,
                withNewsletterPromo,
              })
              navigateBackToContent({ withPayment, withPromptForConsent })
            }
          },

          irisLoginCallback: (props: IIrisCallback) => {
            if (props.success && props.user) {
              handleLoginSuccess(props)
              navigateBackToContent({ withPayment, withPromptForConsent })
            }
          },
        })
      },
      register: standaloneRegistrationPage ? registerStandalone : registerModal,
      login: (options = {}) => {
        if (isLoaded) {
          const {
            onRegistrationSuccess,
            onLoginSuccess,
            withNewsletterPromo = true,
          } = options

          handle.authenticationStarted()
          assignModuleToWindow({
            irisRegisterCallback: async (props: IIrisCallback) => {
              handle.authenticationFinished()

              if (props.success && props.user) {
                handleRegistrationSuccess({
                  ...props,
                  withNewsletterPromo,
                })
                onRegistrationSuccess?.(props.user)
              }
            },
            irisLoginCallback: (props: IIrisCallback) => {
              handle.authenticationFinished()

              if (props.success && props.user) {
                handleLoginSuccess(props)
                onLoginSuccess?.(props.user)
              }
            },
          })
          document.getElementById(LOGIN_BTN_ID)?.click()

          document
            .querySelector('.iiris-modal-box .iiris-close-icon')
            ?.addEventListener('click', e => {
              if (e.isTrusted) {
                handle.authenticationFinished()
              }
            })
        }
      },
      logout: () => {
        document.getElementById(LOGOUT_BTN_ID)?.click()
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded, isClientLoaded, eln],
  )

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
      <ScriptList env={environment} />
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => useContext(AuthenticationContext)!
