import jwtDecode from 'jwt-decode'

export type DecodedUserAccessToken = {
  sub: string
  'cognito:groups': [string]
  iss: string
  version: number
  client_id: string
  origin_jti: string
  token_use: 'access'
  scope: string
  auth_time: number
  exp: number
  iat: string
  username: string
}

export const decodeToken = (token: string) =>
  typeof token === 'string' && token.length
    ? jwtDecode<DecodedUserAccessToken>(token)
    : undefined

export const getIsUserToken = (cookieToken: string): boolean => {
  try {
    const decoded = decodeToken(cookieToken)
    return Boolean(Number(decoded?.exp) * 1000 - Date.now() > 0)
  } catch (e) {
    return false
  }
}

export const createAccessToken = (token: string | null) => {
  if (token) {
    return token.startsWith('Bearer') ? token : `Bearer ${token}`
  }
  return ''
}
