import { UserProfile } from '../types'
import { DataLayerUser, IrisUser, userEventKeys } from '@scm/redux-store'

export const userPicker = (data: IrisUser | null) => {
  if (typeof data !== 'object' || data === null) return null
  const user: Record<string, string> = {}
  userEventKeys.forEach(k => {
    if (data[k]) {
      user[k] = data[k]
    }
  })
  return user as DataLayerUser
}

export const validationErrors = {
  first_name: 'first name is required',
  last_name: 'last name is required',
  social_accounts: 'social accounts is required when social login is true',
}

export function validateProfile(data: UserProfile['Data']) {
  const errors: string[] = []
  //validate data
  if (!data.first_name) {
    errors.push(validationErrors.first_name)
  }
  if (!data.last_name) {
    errors.push(validationErrors.last_name)
  }
  // TODO: remove string type check after IIRIS fix
  if (
    data.is_social_login &&
    data.is_social_login !== 'false' &&
    !data.social_accounts?.length
  ) {
    errors.push(validationErrors.social_accounts)
  }
  if (errors.length) {
    throw Error(errors.join())
  }
}
