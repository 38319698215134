import { Country, FindCountryByCodeType } from '../types'

type DataType = { data: Country[] } | Country[]

export const findCountryByCode: FindCountryByCodeType = async (
  code,
  callback = () => {},
) => {
  try {
    const countries: DataType = await getIIRISProfileMgr()?.getTaxonomyData(
      'countries?locale=en',
    )
    let list: Country | undefined

    if (Array.isArray(countries)) {
      list = countries.find(country => country.id === code)
    } else if (countries?.data) {
      list = countries.data.find(country => country.id === code)
    }
    callback(list)
    return list
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return undefined
  }
}

export function getIIRISProfileMgr() {
  if (typeof window !== 'undefined') {
    // @ts-expect-error
    const IIRISProfileMgr = window.IIRISProfileMgr

    if (!IIRISProfileMgr) {
      throw Error("The IIRISProfileMgr doesn't exist")
    }

    return IIRISProfileMgr
  }
  return {}
}
